import React, {useRef, useState, useEffect} from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import { AboveBreakpointValue } from 'src/components/Breakpoints';
import { preventScroll, allowScroll } from 'src/libs/scroll';
import Arrow from 'src/icons/Arrow';
import Button from 'src/components/Button';
import CheckboxButton from 'src/components/buttons/CheckboxButton';
import CrossButton from 'src/components/buttons/CrossButton';
import debounce from 'lodash/debounce';
import Heading from 'src/components/Heading';
import Input from 'src/components/form/Input';
import PlusMinusButton from 'src/components/buttons/PlusMinusButton';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';
import WysiwygWrapper from 'src/components/WysiwygWrapper';

const NewsletterWrapper = styled('div')`
    border-bottom: 1px solid ${theme.color.black};

    ${below.tablet_s} {
        overflow: hidden;
        transition: background-color 0.2s;

        &.active {
            background-color: ${theme.color.white};
        }
    }
`;

const NewsletterSection = styled('div')`
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    ${below.tablet_s} {
        justify-content: space-between;
    }

    ${theme.spacing.mobile._20('padding')};
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._12('padding-right')};

    ${theme.spacing.tablet._20('padding')};
    ${theme.spacing.tablet._32('padding-left')};
    ${theme.spacing.tablet._32('padding-right')};

    ${theme.spacing.desktop._20('padding')};
    ${theme.spacing.desktop._32('padding-left')};
    ${theme.spacing.desktop._32('padding-right')};

    .arrow {
        ${theme.spacing.tablet._20('margin-left')};
        ${theme.spacing.desktop._20('margin-left')};
        transition: transform 0.2s;
        height: 21px;
        width: 21px;
    }
    .toggleplus {
        display: none;
    }

    &:hover {
        .arrow {
            transform: translateX(10px);
        }
    }
    ${below.tablet_s} {
        .arrow {
            display: none;
        }
        .toggleplus {
            height: 30px;
            width: 30px;
            display: block;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.completed {
        cursor: default;
    }
`;

const NewsletterSectionHeading = styled('h3')`

    ${theme.fontSize.serif.mobile._24};
    ${theme.fontSize.serif.tablet._24};
    ${theme.fontSize.serif.desktop._24};
    margin: 0;
    padding: 0;
    user-select: none;

    text-align: center;
    font-weight: 400;

    line-height: 1;
    font-family: 'Tiempos';
    display: inline-block;
    font-weight: 300;
`;

const CustomCursorCover = styled('div')`
    ${above.tablet_s} {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: url('/cursor/closeCursor.svg') 28 28, pointer;
        z-index: 1;
    }
`;

const NewsletterCover = styled('div')`
    ${below.tablet_s} {
        max-height: 0;
        transition: max-height 0.2s;
        overflow: hidden;

        .active & {
            max-height: 900px;
        }
    }

    ${above.tablet_s} {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        position: fixed;
        backdrop-filter: blur(${theme.blur.standard.desktop});
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        display: none;
        .active & {
            display: flex;
        }

    }
`;

const NewsletterArea = styled('div')`
    ${theme.spacing.mobile._12('padding')};
    position: relative;
    z-index: 2;

    ${above.tablet_s} {
        background-color: ${theme.color.white};
        width: 720px;
        max-width: 100%;
        border: 1px solid ${theme.color.black};
        padding: 24px;
    }
`;

const NewsletterForm = styled('form')`
    ${above.tablet_s} {
        display: flex;
        flex-wrap: wrap;
    }
`;

const NewsletterFormFieldWrapper = styled('div')`
    ${below.tablet_s} {
        & + & {
            ${theme.spacing.mobile._16('margin-top')};
        }

        & > div {
            & + div {
                ${theme.spacing.mobile._16('margin-top')};
            }
        }
    }

    ${above.tablet_s} {
        display: flex;
        width: 100%;
        & + & {
            ${theme.spacing.tablet._24('margin-top')};
            ${theme.spacing.desktop._24('margin-top')};
        }

        & > div {
            flex: 1;
            &:nth-of-type(2n+1) {
                ${theme.spacing.tablet._16('padding-right')};
            }
        }
    }
`;

const FormFooter = styled('div')`
    ${theme.spacing.mobile._20('margin-top')};
    ${theme.spacing.tablet._20('margin-top')};
    ${theme.spacing.desktop._20('margin-top')};

    width: 100%;

    ${below.tablet_s} {
        display: flex;
    }
`;

const FormFooterApprove = styled('div')`
    ${theme.spacing.tablet._24('margin-bottom')};
    ${theme.spacing.desktop._24('margin-bottom')};

    ${below.tablet_s} {
        flex: 1;
        margin-bottom: 0;
    }
`;

const ApprovalText = styled(Text)`
    margin: auto 0;
`;

const FormFooterButton = styled('div')`

    ${below.tablet_s} {
        flex: 0 0 auto;
    }
`;

const CloseButton = styled('div')`
    position: absolute;
    cursor: pointer;

    ${theme.spacing.tablet._24('top')};
    ${theme.spacing.tablet._24('right')};
    ${theme.spacing.desktop._24('top')};
    ${theme.spacing.desktop._24('right')};
    > div {
        transform: rotate(45deg);
    }

    ${below.tablet_s} {
        display: none;
    }
`;

const NewsletterHeading = styled(Heading)`
    font-family: 'Tiempos';
    font-weight: 400;
    ${theme.fontSize.serif.mobile._24};
    ${theme.fontSize.serif.tablet._24};
    ${theme.fontSize.serif.desktop._24};

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._16('margin-bottom')};
    ${theme.spacing.desktop._20('margin-bottom')};

    ${below.tablet_s} {
        display: none;
    }
`;

const NewsletterInfo = styled(Text)`
    line-height: 1.6em;

    ${theme.spacing.mobile._32('margin-bottom')};
    ${theme.spacing.tablet._24('margin-bottom')};
    ${theme.spacing.desktop._24('margin-bottom')};
`;

const AcceptError = styled(Text)`
    font-size: 12px;
    line-height: 1em;
    color: ${theme.color.berries};
`;

const MobileError = styled('div')`
    ${theme.spacing.mobile._12('margin-top')};
    ${above.tablet_s} {
        display: none;
    }
`;

const DesktopError = styled('div')`
    display: inline-block;
    margin-left: 20px;
    ${below.tablet_s} {
        display: none;
    }
`;

const Newsletter = ({data}) => {
    const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(0);

    const newsletterSection = useRef(null);
    const newsletterFormRef= useRef(null);

    const updateViewportWidth = () => {
        const breakpointValue = AboveBreakpointValue('mobile_l');
        const width = window.innerWidth;

        setCurrentWidth(width);

        if (width <= breakpointValue) {
            allowScroll();
        }
    };

    useEffect(() => {
        updateViewportWidth();
        const debouncedHandleResize = debounce(updateViewportWidth, 100);

        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);


    const {
        button,
        heading,
        newsletterForm,
        text
    } = data;

    const toggleNewsletter = (section) => {
        const breakpointValue = AboveBreakpointValue('mobile_l');

        section.current.classList.toggle('active');

        setShowNewsletterPopup(!showNewsletterPopup);
        updateViewportWidth();

        if (currentWidth > breakpointValue) {
            if (showNewsletterPopup) {
                allowScroll();
            } else {
                preventScroll();
            }
        }
    };

    const submitNewsletterForm = () => {
        // Prevent multiple sends
        if (sending) {
            return;
        }
        setSending(true);

        // create a new XMLHttpRequest
        const xhr = new XMLHttpRequest();
        const formData = new FormData(newsletterFormRef.current);

        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText);

            if(response.success) {
                setCompleted(true);
                toggleNewsletter(newsletterSection);
            }

            if(response.errors) {
                setInvalidInputs(response.errors);
            }

            setSending(false);
        });

        // open the request set headers
        xhr.open('POST', `${process.env.API_URL}/api/newsletter/create`);
        xhr.setRequestHeader('Accept', 'application/json');

        // send the request
        xhr.send(formData);

    };

    const [sending, setSending] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [invalidInputs, setInvalidInputs] = useState([]);

    const successMessage = newsletterForm.form.successMessage || 'Thank you!';
    const errorMessageCheckbox = newsletterForm.privacyCheckboxInput.errorMessage
        ? newsletterForm.privacyCheckboxInput.errorMessage
            : invalidInputs.accept && invalidInputs.accept[0]
                ? invalidInputs.accept[0]
                    : '';

    return (
        <NewsletterWrapper ref={newsletterSection}>
            {completed ? (
                <NewsletterSection className="completed">
                    <NewsletterSectionHeading>{successMessage}</NewsletterSectionHeading>
                </NewsletterSection>
            ) : (
                <NewsletterSection onClick={() => toggleNewsletter(newsletterSection)}>
                    <NewsletterSectionHeading>
                        {currentWidth > 720 ? heading : "Newsletter"}
                    </NewsletterSectionHeading>
                    <div className="arrow">
                        <Arrow />
                    </div>
                    <div className="toggleplus">
                        <PlusMinusButton active={showNewsletterPopup} />
                    </div>
                </NewsletterSection>
            )}
            <NewsletterCover>
                <CustomCursorCover onClick={() => toggleNewsletter(newsletterSection)} />
                <NewsletterArea>

                    <CloseButton onClick={() => toggleNewsletter(newsletterSection)}>
                        <CrossButton />
                    </CloseButton>
                    <NewsletterHeading as="h2">{heading}</NewsletterHeading>
                    <WysiwygWrapper data={text} textComponent={NewsletterInfo} />
                    <NewsletterForm ref={newsletterFormRef}>
                        <NewsletterFormFieldWrapper>
                            <Input
                                required
                                label="First name"
                                name="name"
                                invalid={!!invalidInputs.name}
                            />
                            <Input
                                required
                                label="Last name"
                                name="surname"
                                invalid={!!invalidInputs.surname}
                            />
                        </NewsletterFormFieldWrapper>
                        <NewsletterFormFieldWrapper>
                            <Input
                                required
                                label="E-mail"
                                name="email"
                                invalid={!!invalidInputs.email}
                            />
                            <Input
                                label="Company name"
                                name="company"
                            />
                        </NewsletterFormFieldWrapper>
                        <FormFooter>
                            <FormFooterApprove>
                                <CheckboxButton
                                    fontSizes={['font-size: 12px']}
                                    name="accept"
                                    value="true"
                                >
                                    <WysiwygWrapper
                                        textComponent={ApprovalText}
                                        data={newsletterForm.privacyCheckboxInput && newsletterForm.privacyCheckboxInput.text}
                                        openLinksInNewTab
                                    />
                                </CheckboxButton>
                                {!!invalidInputs.accept &&
                                    <MobileError>
                                        <AcceptError>{errorMessageCheckbox}</AcceptError>
                                    </MobileError>
                                }
                            </FormFooterApprove>
                            <FormFooterButton>
                                <Button small rounded onClick={() => submitNewsletterForm()}>{button}</Button>
                                {!!invalidInputs.accept &&
                                    <DesktopError>
                                        <AcceptError>{errorMessageCheckbox}</AcceptError>
                                    </DesktopError>
                                }
                            </FormFooterButton>
                        </FormFooter>
                    </NewsletterForm>
                </NewsletterArea>
            </NewsletterCover>
        </NewsletterWrapper>
    );
};

Newsletter.propTypes = {
    data: PropTypes.shape({
        button: PropTypes.string,
        heading: PropTypes.string,
        newsletterForm: PropTypes.object,
        text: PropTypes.array,
    })
};

Newsletter.defaultProps = {
    data: {
        button: '',
        heading: '',
        newsletterForm: {},
        text: [],
    }
};

export default Newsletter;
